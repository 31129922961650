<template>
  <div
    class="w-full h-full bg-white relative flex justify-center items-start"
    :style="{ minHeight: `${height}px` }"
  >
    <div class="w-full h-full max-w-7xl flex flex-col">
      <div class="w-full border-b my-16"></div>
      <a href="" class="text-primary py-2 font-semibold">About you</a>
      <a href="" class="text-gray-500 py-2 font-medium hover:text-gray-900"
        >Household</a
      >
      <a href="" class="text-gray-500 py-2 font-medium hover:text-gray-900"
        >Questions</a
      >
      <a href="" class="text-gray-500 py-2 font-medium hover:text-gray-900"
        >Income</a
      >
      <a href="" class="text-gray-500 py-2 font-medium hover:text-gray-900"
        >Assets</a
      >
      <a href="" class="text-gray-500 py-2 font-medium hover:text-gray-900"
        >Expenses</a
      >
      <a href="" class="text-gray-500 py-2 font-medium hover:text-gray-900"
        >Upload Documents</a
      >
      <a href="" class="text-gray-500 py-2 font-medium hover:text-gray-900"
        >Review & Sign</a
      >
    </div>
  </div>
</template>

<script>
import useWindowSize from "../../composables/useWindowSize";
export default {
  setup() {
    const { height } = useWindowSize();

    return {
      height,
    };
  },
};
</script>

<style></style>
